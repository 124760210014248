// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBiO1LcHu3ng8OyXO6I23FFpfFbF3f8Bc",
  authDomain: "mmmm-2d7ea.firebaseapp.com",
  projectId: "mmmm-2d7ea",
  storageBucket: "mmmm-2d7ea.appspot.com",
  messagingSenderId: "66873696981",
  appId: "1:66873696981:web:86e78e35c44d91b172785c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
